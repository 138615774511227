* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.my-react-select .Select-control:before {
  color: #aaa;
  content: 'Flavors';
  line-height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
}

.my-react-select .Select-placeholder + .Select-input,
.my-react-select .Select-input:first-child,
.my-react-select .Select-value:first-child {
  margin-left: 70px;
}